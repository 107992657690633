export const photos = [
  { src: 'images/img05.jpg', width: 4, height: 3 },
  { src: 'images/img71.jpg', width: 3, height: 4 },
  { src: 'images/img01.jpg', width: 4, height: 3 },
  { src: 'images/img04.jpg', width: 3, height: 4 },
  { src: 'images/img10.jpg', width: 4, height: 3 },
  { src: 'images/img12.jpg', width: 4, height: 3 },
  { src: 'images/img20.jpg', width: 3, height: 4 },
  { src: 'images/img21.jpg', width: 3, height: 4 },
  { src: 'images/img54.jpg', width: 4, height: 3 },
  { src: 'images/img55.jpg', width: 4, height: 3 },
  { src: 'images/img30.jpg', width: 3, height: 4 },
  { src: 'images/img31.jpg', width: 3, height: 4 },
  { src: 'images/img33.jpg', width: 3, height: 4 },
  { src: 'images/img34.jpg', width: 3, height: 4 },
  { src: 'images/img35.jpg', width: 3, height: 4 },
  { src: 'images/img38.jpg', width: 3, height: 4 },
  { src: 'images/img39.jpg', width: 4, height: 3 },
  { src: 'images/img42.jpg', width: 3, height: 4 },
  { src: 'images/img47.jpg', width: 4, height: 3 },
  { src: 'images/img48.jpg', width: 3, height: 4 },
  { src: 'images/img50.jpg', width: 2.24, height: 4 },
  { src: 'images/img46.jpg', width: 3, height: 4 },
  { src: 'images/img52.jpg', width: 2.24, height: 4 },
  { src: 'images/img56.jpg', width: 3, height: 4 },
  { src: 'images/img57.jpg', width: 4, height: 3 },
  { src: 'images/img60.jpg', width: 3, height: 4 },
  { src: 'images/img61.jpg', width: 3, height: 4 },
  { src: 'images/img62.jpg', width: 3, height: 4 },
  { src: 'images/img63.jpg', width: 4, height: 4 },
  { src: 'images/img64.jpg', width: 3, height: 4 },
  { src: 'images/img16.jpg', width: 4, height: 3 },
  { src: 'images/img65.jpg', width: 4, height: 3 },
  { src: 'images/img66.jpg', width: 4, height: 3 },
  { src: 'images/img67.jpg', width: 3, height: 4 },
  { src: 'images/img72.jpg', width: 77, height: 80 },
  { src: 'images/img80.jpg', width: 4, height: 3 },
  { src: 'images/img06.jpg', width: 4, height: 3 },
  { src: 'images/img03.jpg', width: 4, height: 3 },
  { src: 'images/img26.jpg', width: 3, height: 4 },
]
