import Information from './components/Information'
import Images from './components/Images'
import './App.css'

function App() {
  return (
    <>
      <header className="mw6 center ph3 mb3">
        <h1 className="mb1">Ida &amp; Erik</h1>
        <div className="flex-m">
          <h2 className="f4 fw1 ma0 mb2 mr3">3 juni 2022</h2>
          <h2 className="f4 fw1 ma0 mb2">
            Villa Soludden, Värmdö.{' '}
            <a
              className="link"
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/maps/place/Villa+Soludden+Konferens,+Event+%26+Br%C3%B6llop/@59.3009722,18.4558694,15z/data=!4m5!3m4!1s0x0:0x6652c7a4c5db4c33!8m2!3d59.3009886!4d18.4557466"
            >
              Visa på karta
            </a>
          </h2>
        </div>
      </header>
      <main>
        <div className="mw8 center mb3">
          <Images />
        </div>
        <div className="mw6 ph3 pv4 center">
          <Information />
        </div>
      </main>
    </>
  )
}

export default App
