export default function Information() {
  return (
    <>
      <h2 className="f4 ma0 mb2">Välkommen på vår bröllopsfest!</h2>
      <p className="ma0 mb2">
        Vi ser väldigt mycket fram emot att få fira tillsammans med er. Barn är
        underbara men den här dagen väljer vi att fira i vuxet sällskap.
      </p>
      <p className="ma0 mb4">
        Vi önskar oss inga presenter men ett bidrag till vår bröllopsresa skulle
        betyda jättemycket!
      </p>
      <h3 className="f5 ma0 mb2">Datum</h3>
      <p className="ma0 mb4">Fredagen den 3 juni, 2022</p>
      <h3 className="f5 ma0 mb2">Plats</h3>
      <p className="ma0 mb4">
        Villa Soludden, Ekviks Gårds väg 60, Värmdö.{' '}
        <a
          className="color-inherit"
          target="_blank"
          rel="noreferrer"
          href="https://www.google.com/maps/place/Villa+Soludden+Konferens,+Event+%26+Br%C3%B6llop/@59.3009722,18.4558694,15z/data=!4m5!3m4!1s0x0:0x6652c7a4c5db4c33!8m2!3d59.3009886!4d18.4557466"
        >
          Visa på karta
        </a>
      </p>
      <h3 className="f5 ma0 mb2">Klädkod</h3>
      <p className="ma0 mb4">Kavaj</p>
      <h3 className="f5 ma0 mb2">Tidslinje</h3>
      <table className="w-100 mb3">
        <tbody>
          <tr>
            <th className="tl fw5">Drop in</th>
            <td className="tr">från 16:00</td>
          </tr>
          <tr>
            <th className="tl fw5">Ceremoni</th>
            <td className="tr">17:00</td>
          </tr>
          <tr>
            <th className="tl fw5">Fördrink</th>
            <td className="tr">18:00</td>
          </tr>
          <tr>
            <th className="tl fw5">Middag &amp; fest</th>
            <td className="tr">19:00</td>
          </tr>
        </tbody>
      </table>
      <h3 className="f5 ma0 mb2">Toastmasters</h3>
      <p className="ma0 mb2">Kajsa Sörlien &amp; Mikael Ahlström</p>
      <p className="ma0 mb2">
        Vid tal, specialkost, övriga önskemål eller andra förfrågningar,
        vänligen kontakta våra toastmasters senast den 26e maj.
      </p>
      <p className="ma0 mb4">
        <a href="mailto:sorlien.ahlstrom@gmail.com" className="color-inherit">
          sorlien.ahlstrom@gmail.com
        </a>
      </p>
    </>
  )
}
