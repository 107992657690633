import { useCallback, useState, useRef, useEffect } from 'react'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { photos } from '../photos'
import { useScroll } from '../utils/scroll'

export default function Images() {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const containerRef = useRef(null)
  const targetRef = useRef(null)
  const { scrollToElement } = useScroll({ containerRef, scrollSpeed: 1 })

  const openLightbox = useCallback((_, { index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  useEffect(() => {
    const scroller = setTimeout(() => {
      scrollToElement(targetRef)
    }, 1500)

    return () => clearTimeout(scroller)
  }, [containerRef, scrollToElement])

  return (
    <>
      <div className="relative">
        <div className="photo-wrapper" ref={containerRef}>
          <Gallery photos={photos} direction="column" onClick={openLightbox} />
          <p ref={targetRef}></p>
        </div>
        <div className="arrow-dn">&darr;</div>
      </div>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel currentIndex={currentImage} views={photos} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
